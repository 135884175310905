const config = {
  WS_BASE_URL: "http://dev1.hirelands.com/api/",
  Data_URL: "http://dev1.hirelands.com/",
  CVs_URL: "http://dev1.hirelands.com",
  Video_URL: "http://dev1.hirelands.com",
  Video_URL_2: "https://videos2.hirelands.com",
  Video_URL_3: "https://videos3.hirelands.com",
  General_Presentation_Job_ID: "626110339d90d616b0464805",
  Candidature_Method: "postuler_directement_one_step",
  DOMAIN_NAME: 'http://dev1.hirelands.com',
  DEMO: true,
  Environment: "Dev",
  Jobs_for_outfrSearchPermanentFr: ["627d34016543d2ab235e8126", "627ce5096543d2ab235e8112", "63590c20265361e3b4a9d971", "627d34086543d2ab235e814a", "627d34086543d2ab235e814a", "627d34086543d2ab235e814a", "627d34086543d2ab235e814a", "627d34086543d2ab235e814a", "627d34086543d2ab235e814a", "627d34086543d2ab235e814a"],
  Default_Job_for_employer_profiles: "627d34016543d2ab235e8126",
  Jobs_for_outfrSearchPermanentFr_v2: [
    { value: "627d34016543d2ab235e8126", label: "Java Alternance" },
    { value: "627ce5096543d2ab235e8112", label: "Java Alternance" },
    { value: "627d34086543d2ab235e814a", label: "Responsable technique IT" },
  ],
  question_types: [
    { value: "Video", label: "Vidéo" },
    { value: "QCM with only one correct answer", label: "QCM avec une seule réponse correcte" },
  ],
  question_QCM_options_for_recruter: [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
    { value: "Option 4", label: "Option 4" },
    { value: "Option 5", label: "Option 5" },
    { value: "Option 6", label: "Option 6" },
  ],

  CandidatesUserIds_for_training: [
    { value: "62dfb0004342db0867cbfc82", label: "france1" },
  ],
  optionsQuestionCatalogs: [
  { value: "6371fcc834a934b7dc91a7f2", label: "Reactjs" },
  { value: "63724a731aa2490b8e70ddb4", label: "Java" },
  { value: "63763cdd4672855ae8df4a8a", label: "Dotnet" },
  ],
  profileTypeInitialLabel: 'Java Alternance',
  profileTypeInitialValue: '627d34016543d2ab235e8126'
}

export default config;