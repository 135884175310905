import { PlusIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Label, Col, Container, Progress, Tooltip, Alert } from 'reactstrap';
import Select from 'react-select'
import { ActiveCompetencesOptions } from 'network/ApiAxios';
import { LevelCompetenciesoptions } from 'options';
import { CreateCompetency } from 'network/ApiAxios';
import { AddOneCompetency } from 'network/ApiAxios';
import AsyncSelect from 'react-select/async';


const AddCompetency = ({ getCandidateCompetencies, competencies,t }) => {
    const [modal, setModal] = useState(false);
    const [activesCompetencies, setActivesCompetencies] = useState([])
    const [newCompetency, setNewCompetency] = useState({
        name: '',
        level: ''
    })
    const [searchText, setSearchText] = useState('');


    const [nameErrors, setNameErrors] = useState(null)
    const validationName = () => {
        let isValid = true;
        if (!newCompetency.name) {
            setNameErrors('Il faut ajouter la compétence')
            isValid = false;
            return isValid
        }
        return isValid
    }


    const getActiveComeptenciesOptions = async () => {
        try {
            const { data } = await ActiveCompetencesOptions()
            console.log(data);
            setActivesCompetencies(data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getActiveComeptenciesOptions()
    }, [])

    const filterOptions = inputValue => {
        return activesCompetencies.filter(option =>
            option.name.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            const filteredOptions = filterOptions(inputValue);
            callback(filteredOptions);
        }, 500); // Adjust the delay as needed
    };

    const handleSearchChange = newValue => {
        setSearchText(newValue);
    };

    const toggle = () => setModal(!modal);
    const handleNameChange = (selectedOption) => {
        //console.log("Selected Name:", selectedOption);
        setNewCompetency({ ...newCompetency, name: selectedOption.name });
    };

    const handleLevelChange = (selectedOption) => {
        //console.log("Selected Level:", selectedOption);
        setNewCompetency({ ...newCompetency, level: selectedOption.value });
    };

    const creatingCompetenceForCandidate = async () => {
        try {
            toggle()
            await CreateCompetency()
            await getCandidateCompetencies()
        } catch (error) {
            console.log(error);
        }
    }

    const AddNewCompetency = async () => {
        try {
            //console.log('111111',competencies._id,newCompetency);
            if (validationName()) {
                await AddOneCompetency(competencies?._id, newCompetency)
                toggle()
                await getCandidateCompetencies()
            }

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <Button onClick={creatingCompetenceForCandidate} style={{ backgroundColor: '#5e72e4', color: 'white', float: 'right', whiteSpace: 'normal' }}>
                <PlusIcon style={{ height: '1.2rem' }} />
            </Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{t('add')} {t('OutFrSidebarKey8')}</ModalHeader>
                <ModalBody>
                    <Container>

                        <Label>{t('OutFrSidebarKey8')}</Label>
                        <Row className='mb-5'>

                            <Col sm='12'>
                                <AsyncSelect
                                    loadOptions={loadOptions}
                                    onInputChange={handleSearchChange}
                                    //options={activesCompetencies}
                                    getOptionLabel={option => option.name}
                                    onChange={handleNameChange}
                                    placeholder="Search and select an option..."

                                />
                            </Col>
                            {nameErrors && <Alert color='danger'>{nameErrors}</Alert>}
                        </Row>
                        <Label >{t('Level')}</Label>
                        <Row>
                            
                            <Col sm='12'>
                                <Select
                                    options={LevelCompetenciesoptions}
                                    onChange={handleLevelChange}
                                />
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={AddNewCompetency}>
                        {t('add')}
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        {t('cancel')}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AddCompetency