import { FetchLastActivePopup } from 'network/ApiAxios';
import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Button, CardImg, Col, Row } from 'reactstrap';
import popupImg from '../../assets/img/brand/popup.png';


const Popups = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [popup, setPopup] = useState(null);

    const GetLastActivePopUp = async () => {
        try {
            const { data } = await FetchLastActivePopup()
            setPopup(data)
            data && setShowPopup(true)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetLastActivePopUp()
    }, [])

    return (
        <div>
            {showPopup && (
                <Popup
                    contentStyle={{
                        width: '500px', 
                        padding: '20px', 
                        borderRadius: '10px', 
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                    open={showPopup}
                    onClose={() => { }}
                    modal
                    closeOnDocumentClick={false}>
                    <h2>{popup?.type}</h2>
                    <Row style={{ textAlign: 'center' }} className='p-4'>
                        <Col sm='3'>
                            <CardImg
                                variant=""
                                alt="Responsive image"
                                src={popupImg}
                            />
                        </Col>
                        <Col sm='8'>
                            <div dangerouslySetInnerHTML={{ __html: popup?.description }} />

                        </Col>
                    </Row>
                    <Row>
                        <Col sl='6'></Col>
                        <Col sm='6'>
                            <Button
                                color="primary"
                                className='mb-2'
                                style={{
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setShowPopup(false)}
                            >
                                J'ai compris
                            </Button>
                        </Col>
                    </Row>
                </Popup>
            )}
        </div>
    )
}

export default Popups