import Header from 'components/Headers/Header'
import HomeNavbar from 'components/Navbars/HomeNavbar'
import SearchTalent from 'components/other/SearchTalent'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap'
import qs from "query-string";
import { useLocation,useHistory } from 'react-router-dom'
import OneCardForTalentedCandidateForEmployer from 'components/employer/OneCardForTalentedCandidateForEmployer'
import ReactPaginate from 'react-paginate'
import { getTalentedCandidatesAccordingToKeywordAndLocation } from 'network/ApiAxios'
import SearchTalentV1 from 'components/other/SearchTalentV1'

const TalentedCandidatesListV1 = () => {
    const [pageNb, setPageNb] = useState(1);
    const [limit, setLimit] = useState(24);
    const [users, setUsers] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [usersNb, setUsersNb] = useState(null)
    
    const history = useHistory();
    const location = useLocation();
    const query = qs.parse(location.search);
    //console.log('query', query)
    const Search = query?.search;
    const Location = query?.location;


    const HandleClick= (Search,Location)=>{
        setPageNb(1)
        fetchTalentedCandidatesAccordingToKeywordAndLocation(Search, Location)
        history.push(`/home/talentedCandidatesListsV1?search=${Search}&location=${Location}`)
    }

    const fetchTalentedCandidatesAccordingToKeywordAndLocation = async (Search, Location) => {
        try {
            const { data } = await getTalentedCandidatesAccordingToKeywordAndLocation(pageNb, limit, Search, Location)
            setUsers(data.results)
            //setUsersNb(data.count)
            //setPageCount(Math.ceil(data.count / limit))

            setUsersNb((data?.count > 24) ? 300 : data.count)
            setPageCount((data?.count > 24) ? 15 : Math.ceil(data.count / limit) )
        } catch (error) {
            console.log(error)
        }
    }

    const handlePageClick = (e) => {
        setPageNb(e.selected + 1);
        if(e.selected + 1 > 1){
           history.push('/homepage/register')
        }
    };

    
    /* useEffect(() => {
        fetchTalentedCandidatesAccordingToKeywordAndLocation(Search, Location)
    }, [pageNb]) */
    useEffect(() => {
        (Search && Location) && fetchTalentedCandidatesAccordingToKeywordAndLocation(Search, Location)
    }, [])


    return (
        <div style={{ width: '100%', overflow: 'hidden'}}>
            <div className="main-content">
                <HomeNavbar />
                <div className="homeHeader bg-grad pb-1 pt-4 pt-md-1">
                    <Container>
                        <div className="header-body text-center mb-7">
                            <Row className="justify-content-center">
                                <Col lg="5" md="6">

                                </Col>
                            </Row>
                        </div>
                    </Container>

                </div>

                <Header />
                {/* Page content */}
                <SearchTalentV1
                    Search={Search}
                    Location={Location}
                    onClick={HandleClick}
                />
                <Row className='justify-content-center mt-5'>
                    <Card className='bg-transparent shadow mr-5 ml-5' style={{ width: '90%'}}>
                        <CardHeader>
                            {(Search && usersNb) && <h2>{`${(usersNb >= 300) ? "Plus de" : ""} ${/*(usersNb && usersNb >= 300) ? 300 :*/ usersNb} candidats "${Search}" disponibles en ${Location}`} </h2>}
                        </CardHeader>
                        <CardBody>
                            <div className='d-flex justify-content-center flex-wrap' style={{gap:'20px' /*, display:'grid', gridTemplateColumns: "auto auto auto"*/}}>
                            {users?.map((user,i) => <OneCardForTalentedCandidateForEmployer
                                //user={user}
                                key={i}
                                id={user._id}
                                location={user?.location}
                            />)}
                            </div>
                        </CardBody>
                       { (usersNb !== null) && <CardFooter className="d-flex justify-content-center align-items-center">
                                <Row>
                                    <Col>
                                    <h4>{`${pageNb} - ${limit} ${usersNb >= 300 ? `sur plus de ${usersNb} résultats` : "" } `}</h4>
                                    </Col>
                                </Row>
                                <ReactPaginate
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </CardFooter>}
                    </Card>


                </Row>
            </div>
        </div>
    )
}

export default TalentedCandidatesListV1