import axios from "axios";
import config from "../config";

// const https = require('https');
//
// const agent = new https.Agent({
//     rejectUnauthorized: false,
// });
const token = localStorage.getItem("token");

var config_post_with_data = {
  method: 'post',
  baseURL: config.WS_BASE_URL,
  // url: 'http://localhost:5100/api/playbooks/createOnePlaybook',
  headers: { 
    'Authorization': token ? token : "", 
    'Content-Type': 'application/json'
  },
  // data : data
};

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

export const FetchLastActivePopup = async()=> await instance.get(`popups/lastActivePopup`);

export const fetchJobAppById = async(id)=> await instance.get(`/jobApplication/getJobApplicationById/${id}`);
export const fetchJobAppCandidatesWithAnonym = async(userId)=> await instance.get(`jobApplication/fetchJobAppCandidatesWithAnonym/${userId}`);

//training for Candidates
export const fetchOneTraining = async(id)=> await instance.get(`TrainingCandidates/${id}`)
export const fetchActiveTrainings = async(type)=> await instance.get(`TrainingCandidates/active?type=${type}`);


//openAI
export const getOpenAiOralQCsReportByQC = async(jobAppId, QCjobID) => await instance.get(`openAi/oralQCAanalysis/${jobAppId}/${QCjobID}`);
export const fetchOpenAiCheatingCodeAnalysis = async(jobId) => await instance.get(`openAI/CheatingAnalysisForFollowUpGPTQuestions/${jobId}`); 
export const GeneratefollowUpQuestionsForCodeCandidateResponse  = async(questionId,item)=> await instance.post(`openAI/GeneratefollowUpQuestionsForCodeCandidateResponse/${questionId}`,item);
export const GenerateIAQuestionsFomJobDescription  = async(jobId, nbQ)=> await instance.get(`openAI/GenerateQuestionsFomJobDescription/${jobId}/${nbQ}`);
export const fetchOpenAiCriteriaAnalysis = async(jobId) => await instance.get(`openAI/CriteriaAcceptanceAnalysis/${jobId}`);
export const getOpenAiJobAppCVAnalysis = async(id)=> await instance.get(`openAi/analyzeCVByJobApp/${id}`);
export const ParseCVCandidate = async(id)=> await instance.get(`openAi/ParseResumeData/${id}`)
export const getOpenAiCodeQuestionAnalysisByJobApp = async(jobAppId) => await instance.get(`openAi/CodeEvaluation/${jobAppId}`);
export const getOpenAiOralQCsReportByJobApp = async(jobAppId, assistant_id) => await instance.get(`openAi/EvaluateOralQCByJobApp/${jobAppId}?assistant_id=${assistant_id}`);
export const getopenAiVideoEvaluation = async(item, assistant_id)=> await instance.post(`openAi/EvaluateVideoResponse?assistant_id=${assistant_id}`,item)

export const getOneProfileTypeByID = async(profileTypeID) => await instance.get(`/profiletypes/getProfileTypeById/${profileTypeID}`)

export const ConfigureOptionsQuestions = async(CriteriaId,configOpts)=> await instance.put(`JobAcceptanceCriteria/ConfigureOptionsQuestions/${CriteriaId}`,configOpts)
export const UpdateConfiguredOptionsQuestions = async(CriteriaId,configOpts)=> await instance.put(`JobAcceptanceCriteria/UpdateAppendedOptionsQuestionsToCriteria/${CriteriaId}`,configOpts)

export const ConfigureACWithQuestion = async(CriteriaId,questionId)=> await instance.put(`JobAcceptanceCriteria/ConfigureCriteria/${CriteriaId}`, questionId)

// Question
export const fetchQuestions = async(pageNb, pagination)=> await instance.get(`/questions/TemplateQuestions?pageNumber=${pageNb}&pagination=${pagination}`);
export const CreateQuestion = async(jobId,question)=> await instance.post(`jobs/createQuestionV2/${jobId}/question`,question);
export const UpdateQuestionsByJobId = async(jobId, updatedQuestions)=> await instance.put(`jobs/updateQuestionsByJob/${jobId}`,updatedQuestions)
export const fetchQuestionsById = async(jobId)=> await instance.get(`jobs/${jobId}`)
export const ExecuteCode = async(item, codingQId) => await instance.post(`CodingQuestions/execute/${codingQId}`,item)

//Contract Type
export const getActiveContracTypes = async ()=> await instance.get('contractTypes/getAllActive')


export const fetchJobAppForGeneralPresentation = async(userID) => await instance.get(`jobApplication/getJobAppForGeneralPresentationByUserId/${userID}`)
//jobs
export const UpdatJobOneField = async(jobId,fieldValue,item) => await instance.put(`jobs/updateJobOneField/${jobId}/${fieldValue}`, item)
export const fetchActiveInternshipOffersForFrCandidateNumber = async()=> await instance.get("jobs/ActiveInternshipOffersForFrCandidatesNumber")
export const fetchActiveInternshipOffersForFrCandidate = async(pageNb, limit)=> await instance.get(`jobs/ActiveInternshipOffersForFrCandidates/${pageNb}/${limit}`)
export const getOneJobByID = async(jobId)=> await instance.get(`jobs/${jobId}`)
export const AppendNewCodingQuestionToJob = async(jobID, newCQIDS) => await instance.put(`jobs/appendCodingCToJob/${jobID}`,newCQIDS);
export const fetchAllActiveJobOffersWithPayOrTJMConditionNumber = async(fieldValue) => await instance.get(`jobs/getActiveJobOffersWithPayOrTJMConditionNumber/${fieldValue}`)
export const fetchAllActiveJobOffersWithPayOrTJMCondition = async(fieldValue,pageNumb, paginationNb) => await instance.get(`jobs/getActiveJobOffersWithPayOrTJMCondition/${fieldValue}/${pageNumb}/${paginationNb}`)
export const fetchActiveJobOffersInFranceWithPayOrTJMConditionNumber = async(fieldValue) => await instance.get(`jobs/getActiveJobOffersInFranceWithPayOrTJMConditionNumber/${fieldValue}`)
export const fetchActiveJobOffersInFranceWithPayOrTJMCondition = async(fieldValue,pageNumb, paginationNb) => await instance.get(`jobs/getActiveJobOffersInFranceWithPayOrTJMCondition/${fieldValue}/${pageNumb}/${paginationNb}`)
export const FetchMyJobs = async(userId)=> await instance.put('jobs',userId)

//coding Q
export const fetchActiveCodingQ = async()=> await instance.get('CodingQuestions/active')

//score bu QC
export const CalculateScorebyQC = async(candidatureId) => await instance.get(`jobApplication/calculateJobApplicationScoreByQuestionCatalog/${candidatureId}`)

export const getJob = async(jobID)=> await instance.get(`jobs/${jobID}`)

export const getCandidateJobApplications = async(candidatId) => instance.get(`jobApplication/getJobApplicationsByUserID/${candidatId}`)

//Candidate Competencies
export const DeleteCompetency = async(competencyId,OneCompetencyId)=> await instance.put(`competencies/v1/delete/${competencyId}/${OneCompetencyId}`)
export const EditLevelOneCompetency = async(competencyId,OneCompetencyId, level)=> await instance.put(`competencies/v1/editLevel/${competencyId}/${OneCompetencyId}`,level)
export const AddOneCompetency = async(compentencyId,competency)=> await instance.put(`competencies/v1/edit/${compentencyId}`,competency)
export const CreateCompetency = async() => await instance.post('competencies/v1/add')

export const ActiveCompetencesOptions = async()=> await instance.get('competencies/getAllActiveCompetencies')

//Fake User
export const getOneFakeUser = async(fakeUserId) => await instance.get(`FakeUsers/${fakeUserId}`)
//USER API
export const AddOrUpdateUserPayments = async(id, payment)=> await instance.post(`users/payments/${id}`,payment);
export const FetchLastPostedCV = async(id) => await instance.get(`/users/getLastPostedCVByUserID/${id}`);
export const EditUserCV = async(id,formdata)=> await instance.put(`users/editCV/${id}`,formdata);
export const getTalentedCandidatesAccordingToKeywordAndLocation = async(pageNb,limit,search, location) => await instance.get(`users/talents/${pageNb}/${limit}?search=${search}&location=${location}`);
export const EditProfileImage = async(id,image)=> await instance.put(`users/EditUserImage/${id}`,image);
export const SearchCandidatesNumberByCompany = async(companyId,search) => await instance.get(`jobs/searchCandidateNumber/${companyId}?search=${search}`);
export const SearchCandidatesByCompany = async(companyId,pageNb,limit, search)=> await instance.get(`jobs/searchCandidate/${companyId}?pageNumber=${pageNb}&pagination=${limit}&search=${search}`)
export const getTalentedCandidatesAccordingToProfileTypeAndLocationNumber = async(profileType, location)=> await instance.get(`users/talentsCandidatesNumber?profileType=${profileType}&location=${location}`)
export const getTalentedCandidatesAccordingToProfileTypeAndLocation = async(pageNb,limit,profileType, location) => await instance.get(`users/talentsCandidates/${pageNb}/${limit}?profileType=${profileType}&location=${location}`);
export const getCompetenciesByCandidate = async(userId)=> await instance.get(`competencies/v1/MyCompetencies/${userId}`)
export const EditUserParameters = async(user) => await instance.post(`users/editUserParameters`,user)
export const EditProfileOutFrCDICandidate = async (profile,userID)=> await instance.put(`outFrSearchCdiFrProfiles/editParametersByUserID/${userID}`,profile)
export const EditProfileOutFrFreelanceCandidate = async (profile,userID)=> await instance.put(`outFrSearchFreelanceInFranceProfiles/Edit/${userID}`,profile)

export const EditProfileFrCandidate = async (profile,userID)=> await instance.put(`profiles/Edit/${userID}`,profile)


export const getUserById = async(userId)=> await instance.get(`users/getprofilebyuserid/${userId}`)
export const getProfileOutFrUserById = async(userId)=> await instance.get(`outFrSearchCdiFrProfiles/getprofilebyuserid/${userId}`)
export const getProfileFrUserByID = async(userId) => await instance.get(`/profiles/getprofilebyuserid/${userId}`)

export const getMyCompetencies = async(userId) => await instance.get(`candidatesCompetencies/getCompetenciesByUserID/${userId}`)
export const getProfileProgressForOutFrCandidate= async(userId)=> await instance.get(`users/outfrancecheckprofileprogress/${userId}`)
export const getProfileProgressForFrCandidate= async(userId)=> await instance.get(`users/FranceCheckProgressPRofile/${userId}`)

export const createSessionForCheckoutPayment = async(info)=> await instance.post('subscription/checkout-training-session',info);
export const createUniqueSessionForCheckout = async(info)=> await instance.post('subscription/session',info)

//subscription
export const CreateSessionForPortailBillingStripe = async(custId)=> await instance.get(`subscription/portalBillingByCustomer/${custId}`);
export const CheckCandidateSubscription = async(email)=> await instance.get(`subscription/check/${email}`);
export const FetchSubscriptionProduct = async(productId)=> await instance.get(`subscription/price/${productId}`);
export const getAllActiveSubscriptionProducts = async()=> await instance.get('SubscriptionStripeProducts/active');
export const getAllSubscriptionProducts = async()=> await instance.get('subscription/prices')

export const fetchOfferByCompany = async(companyID)=> await instance.get(`offers/OneOfferByCompany/${companyID}`)

export const CreateOrUpdateOffer = async(offer)=> await instance.post('offers/createOrEditOffer',offer)


export const getGeneralParam = async()=> await instance.get('GeneralParameter/generalParameter')
export const fetchGeneralParamByLabel = async(label)=> await instance.get(`GeneralParameter/getOneParamByLabel/${label}`)

export const ResetQCMQuestionsCatalogAnalysis = async(jobAppId,fieldName,item)=> await instance.put(`jobApplication/updateJobApplicationOneField/${jobAppId}/${fieldName}`,item)
export const getAllActiveQuestionCatalogs = async()=> await instance.get('QuestionCatalog/getAllActiveQUestionsCatalogs')

export const getOpenedOutFrProfilesCandidatesForRecruiters = async(Limit,PageNumber) => await instance.get(`users/getOpenOutFrCandidatesProfilesWithPassedInterview?limit=${Limit}&pageNumber=${PageNumber}`)
export const getOpenedProfilesCandidatesForRecruiters = async(Limit,PageNumber) => await instance.get(`users/getOpenAndQualifiedCandidatesProfilesWithPassedInterview?limit=${Limit}&pageNumber=${PageNumber}`)

export const getProfilesCandidatesthatacceptInterviewDemands = async(companyId,Limit,PageNumber)=> await instance.get(`interviewAccessRequest/getAcceptedInvitationsAccessByCompanyWithCandidateInterviewPassed/${companyId}?limit=${Limit}&pageNumber=${PageNumber}`)

export const getUserByEmail = async(email)=> await instance.get(`users/getUserByEmail/${email}`)

export const GetAllAcceptedInvitationsAccessByCompany = async(companyID)=> await instance.get(`interviewAccessRequest/getAcceptedInvitationsAccessByCompany/${companyID}`)

export const UpdateInVitationAccess = async(newInvit) => await instance.put(`interviewAccessRequest/editParameters/${newInvit._id}`,newInvit)

export const getAllInvitationsByCandidateInFr =async (inFrCandidateId)=> await instance.get(`interviewAccessRequest/getInvitationsByCandidat/${inFrCandidateId}`)

export const getAllInvitationsByCompany = async(companyID)=>await instance.get(`interviewAccessRequest/getInvitationsByCompanies/${companyID}`)

export const saveInvitationRequest = async (invitation)=> await instance.post('interviewAccessRequest/createNewOne',invitation)

// export const getAll = async () => await instance.post("users/all");

// export const getCompanyUsers = async (companyName) => await instance.get(`employer/getcompanyusers/${companyName}`);
export const getCompanyUsers = async (companyName) => await instance.get(`employer/getcompanyusers/${companyName}`);
export const getProfileTypeAccessRuleByUserId = async (userID) => await instance.get(`profiletypesAccessRules/getProfileTypeAccessRuleByUserId/${userID}`);
export const getActiveProfileTypes = async()=> await instance.get('profiletypes/getAllActiveProfileTypes');
export const getProfileTypes = async () => instance.get(`profiletypes/getAllProfileTypes`);
export const getAccessRuleByProfileTypeIDAndUserID = async (profileTypeID, userID) => await instance.get(`profiletypesAccessRules/getAccessRuleByProfileTypeIDAndUserID/${profileTypeID}/${userID}`);
export const getAccessRuleByJobIDAndUserID = async (jobID, userID) => await instance.get(`profiletypesAccessRules/getAccessRuleByJobIDAndUserID/${jobID}/${userID}`);
export const getCompanyUsersForTraningsTracking = async (trainingCampanyID) => await instance.get(`trainingsEmployee/getTrainingsEmployees/${trainingCampanyID}`);

export const createProfileTypeAccessRule = async (
  profileType, 
  userID, 
  visibility,
  ) => await instance.post(`profiletypesAccessRules/createProfileTypeAccessRule`, 
  {
    profileType,
    userID,
    visibility,
  }
);





export const createTrainingEmployeeByEmailAddress = async (
  email, 
  trainingCampanyID, 
  ) => await instance.post(`trainingsEmployee/createTrainingEmployeeByEmailAddress`, 
  {
    email,
    trainingCampanyID,
  }
);



export const updateVisibility = async (
  accessRuleID,
  visibility,
  ) => await instance.put(`profiletypesAccessRules/updateVisibility/${accessRuleID}`, 
  {
    visibility,
  }
);



export const register = async (
  firstname,
  lastName,
  email,
  company,
  companyEmail,
  phone,
  webSite,
  activity,
  description,
  location,
  password,
  role,
) =>
  await instance.post("users/register", {
    firstname,
    lastName,
    email,
    company,
    companyEmail,
    phone,
    webSite,
    activity,
    description,
    location,
    password,
    role,
  });

  export const registerEmployer = async (
    name,
    lastName,
    email,
    company,
    companyEmail,
    phone,
    webSite,
    activity,
    description,
    location,
    password,
    role,
    acceptNewsletterReception,
  ) =>
    await instance.post("users/registerEmployer", {
      name,
      lastName,
      email,
      company,
      companyEmail,
      phone,
      webSite,
      activity,
      description,
      location,
      password,
      role,
      acceptNewsletterReception,
    });

  export const registerUserCompany = async (
    firstname,
    lastName,
    email,
    company,
    password,
    role,
    employerCampanyID,
    accessLevel,
  ) =>
    await instance.post("employer/registerUserCompany/", {
      firstname,
      lastName,
      email,
      company,
      password,
      role,
      employerCampanyID,
      accessLevel,
    });

    export const editUserCompany = async (user) => await instance.post("employer/editusercompany", user);

export const registerCand = async (
  name,
  lastName,
  email,
  studyLevel,
  yearsOfExperience,
  adress,
  password,
  role,
  projets,
  location,
  whatsappnumber,
  linkedin,
  acceptNewsletterReception,
  profileType,
) =>
  await instance.post("users/registerCandidat", {
    name,
    lastName,
    email,
    studyLevel,
    yearsOfExperience,
    adress,
    password,
    role,
    projets,
    location,
    whatsappnumber,
    linkedin,
    acceptNewsletterReception,
    profileType,
  });

export const confirmRegister = async (id) =>
  await instance.post(`users/confirm/${id}`);

export const forgotPassword = async (email) =>
  await instance.post("users/forgotpassword", { email });

export const checkPassReset = async (token) =>
  await instance.post(`users/check/resetpass/${token}`);
  

export const confirmReset = async (id, password) =>
  await instance.post(`users/resetpass/${id}`, { password });

export const login = async (email, password) =>
  await instance.post("users/login", { email, password });

export const logout = async (token) =>
  await instance.post("users/logout", { token });

export const edit = async (formData) =>{ console.log("sikimimi",formData)
  await instance.post("/users/edit",  formData );}

export const createOnePlaybook = async (title, description, creatorID, step_1_Description, step_1_URL, step_2_Description, step_2_URL, step_3_Description, step_3_URL) => 
    {
      // console.log("ApiAxios: createOnePlaybook: ");
      // console.log("ApiAxios: createOnePlaybook: title: ", title);
      // console.log("ApiAxios: createOnePlaybook: description: ", description);
      // console.log("ApiAxios: createOnePlaybook: creatorID: ", creatorID);
      // console.log("ApiAxios: createOnePlaybook: step_1_Description: ", step_1_Description);
      // console.log("ApiAxios: createOnePlaybook: step_1_URL: ", step_1_URL);
      // console.log("ApiAxios: createOnePlaybook: step_2_Description: ", step_2_Description);
      // console.log("ApiAxios: createOnePlaybook: step_2_URL: ", step_2_URL);
      // console.log("ApiAxios: createOnePlaybook: step_3_Description: ", step_3_Description);
      // console.log("ApiAxios: createOnePlaybook: step_3_URL: ", step_3_URL);
      var data = JSON.stringify({
        "title": title,
        "description": description,
        "creatorID": creatorID,
        "step_1_Description": step_1_Description,
        "step_1_URL": step_1_URL,
        "step_2_Description": step_2_Description,
        "step_2_URL": step_2_URL,
        "step_3_Description": step_3_Description,
        "step_3_URL": step_3_URL
      });
      
      config_post_with_data.url = 'playbooks/createOnePlaybook';
      config_post_with_data.data = data;
      
      const response = await axios(config_post_with_data);

        
        // console.log("ApiAxios: createOnePlaybook: ");
        // console.log("ApiAxios: createOnePlaybook: response: ", response);
        return response;
      

      
    {/*
       await instance_2.post("playbooks/createOnePlaybook/", 
        {
        "title": title,
        "description": description,
        "creatorID": creatorID,
        "step_1_Description": step_1_Description,
        "step_1_URL": step_1_URL,
        "step_2_Description": step_2_Description,
        "step_2_URL": step_2_URL,
        "step_3_Description": step_3_Description,
        "step_3_URL": step_3_URL
      }
      );
    */}

    

  };

  export const getOnePlaybookByID = async (playbookID) => await instance.get(`playbooks/getOnePlaybookByID/${playbookID}`);
  export const getPlaybooksForOneCompany = async (companyID) => await instance.get(`playbooks/getPlaybooksForOneCompany/${companyID}`);
  export const sendInvitationEmailForJobApp = async (recruiterEmail, candidateEmail, jobAppId) => 
  {
    // console.log("ApiAxios: createOnePlaybook: ");
    // console.log("ApiAxios: createOnePlaybook: title: ", title);
    // console.log("ApiAxios: createOnePlaybook: description: ", description);
    // console.log("ApiAxios: createOnePlaybook: creatorID: ", creatorID);
    // console.log("ApiAxios: createOnePlaybook: step_1_Description: ", step_1_Description);
    // console.log("ApiAxios: createOnePlaybook: step_1_URL: ", step_1_URL);
    // console.log("ApiAxios: createOnePlaybook: step_2_Description: ", step_2_Description);
    // console.log("ApiAxios: createOnePlaybook: step_2_URL: ", step_2_URL);
    // console.log("ApiAxios: createOnePlaybook: step_3_Description: ", step_3_Description);
    // console.log("ApiAxios: createOnePlaybook: step_3_URL: ", step_3_URL);
    var data = JSON.stringify({
      "recruiterEmail": recruiterEmail,
      "candidateEmail": candidateEmail,
      "jobAppId": jobAppId
    });
    
    config_post_with_data.url = 'users/sendInvitationEmailForJobApp';
    config_post_with_data.data = data;
    
    const response = await axios(config_post_with_data);
    // console.log("ApiAxios: createOnePlaybook: ");
    // console.log("ApiAxios: createOnePlaybook: response: ", response);
      return response;
    
};
export const sendEmailForJobApp = async (recruiterEmail, candidateEmail, jobAppId, emailTarget) => 
{
  // console.log("ApiAxios: createOnePlaybook: ");
  // console.log("ApiAxios: createOnePlaybook: title: ", title);
  // console.log("ApiAxios: createOnePlaybook: description: ", description);
  // console.log("ApiAxios: createOnePlaybook: creatorID: ", creatorID);
  // console.log("ApiAxios: createOnePlaybook: step_1_Description: ", step_1_Description);
  // console.log("ApiAxios: createOnePlaybook: step_1_URL: ", step_1_URL);
  // console.log("ApiAxios: createOnePlaybook: step_2_Description: ", step_2_Description);
  // console.log("ApiAxios: createOnePlaybook: step_2_URL: ", step_2_URL);
  // console.log("ApiAxios: createOnePlaybook: step_3_Description: ", step_3_Description);
  // console.log("ApiAxios: createOnePlaybook: step_3_URL: ", step_3_URL);
  var data = JSON.stringify({
    "recruiterEmail": recruiterEmail,
    "candidateEmail": candidateEmail,
    "jobAppId": jobAppId,
    "emailTarget": emailTarget
  });
  
  config_post_with_data.url = 'users/sendEmailForJobApp';
  config_post_with_data.data = data;
  
  const response = await axios(config_post_with_data);
  // console.log("ApiAxios: createOnePlaybook: ");
  // console.log("ApiAxios: createOnePlaybook: response: ", response);
    return response;
  
};

export const sendEmailForOutFrUserManagement = async (recruiterEmail, candidateEmail, emailTarget) => 
{
  // console.log("ApiAxios: createOnePlaybook: ");
  // console.log("ApiAxios: createOnePlaybook: title: ", title);
  // console.log("ApiAxios: createOnePlaybook: description: ", description);
  // console.log("ApiAxios: createOnePlaybook: creatorID: ", creatorID);
  // console.log("ApiAxios: createOnePlaybook: step_1_Description: ", step_1_Description);
  // console.log("ApiAxios: createOnePlaybook: step_1_URL: ", step_1_URL);
  // console.log("ApiAxios: createOnePlaybook: step_2_Description: ", step_2_Description);
  // console.log("ApiAxios: createOnePlaybook: step_2_URL: ", step_2_URL);
  // console.log("ApiAxios: createOnePlaybook: step_3_Description: ", step_3_Description);
  // console.log("ApiAxios: createOnePlaybook: step_3_URL: ", step_3_URL);
  var data = JSON.stringify({
    "recruiterEmail": recruiterEmail,
    "candidateEmail": candidateEmail,
    "emailTarget": emailTarget
  });
  
  config_post_with_data.url = 'users/sendEmailForOutFrUserManagement';
  config_post_with_data.data = data;
  
  const response = await axios(config_post_with_data);
  // console.log("ApiAxios: createOnePlaybook: ");
  // console.log("ApiAxios: createOnePlaybook: response: ", response);
    return response;
  
};
