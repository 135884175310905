import Popups from 'components/other/Popup'
import useCustomTranslation from 'components/other/useCustomTranslation'
import PageTitleStyle from 'components/styles/PageTitleStyle'
import PageSpace from 'components/styles/pageSpace'
import CandidateLayoutV3 from 'layouts/CandidateLayoutV3'
import { FetchLastActivePopup } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'

const WelcomeCandidateView = () => {
    const {t} = useCustomTranslation();
    return (
        <>
            <CandidateLayoutV3 />
            <Container>
                <PageSpace />
                <PageTitleStyle
                    title={t('Welcome')}
                >

                </PageTitleStyle>
                <Row>
                <Col sm='2'></Col>
                <Col col='10'>
                    <Popups/>
                </Col>
            </Row>
            </Container>
           
        </>
    )
}

export default WelcomeCandidateView