import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Card,
    CardBody,
    Form,
    FormGroup,
    Input,
    Row,
    CardHeader,
    Button,
    Alert,
    Label,
    FormText,
} from "reactstrap";
import ProfilePartie1 from "./ProfilePartie1";
import CardComp from "./CardComp";
import Select from "react-select";
import './CSS/Profile.css'
import { options_family_Situation } from "options";
import { Link } from 'react-router-dom'
import { options_Pays } from "options";
import { EditUserParameters } from "network/ApiAxios";
import { EditProfileFrCandidate } from "network/ApiAxios";
import CandidateCompetencies from "../CandidateCompetencies";
import MyInterviewTitles from "./MyInterviewTitles";
import FillProfileModal from "./FillProfileModal";


const FormComp = ({ candidateUser, profileC, competencies, getUser, getProfileCandidate, candidateProgressProfile, getCandidateCompetencies, jobApplication,t }) => {
    /* const CompetenciesAccordingToProfile = () => {
        switch (competencies?.profileType) {
            case "Chef de projet" || "Manager" || "Autre type de profil":
                return (
                    <ul>
                        <li>ITIL</li>
                        <p className="para">{competencies?.ITIL_Autres}</p>
                        <li>Management</li>
                        <p className="para">{competencies?.Management_Autres}</p>
                        <li>SCRUM</li>
                        <p className="para">{competencies?.SCRUM_Autres}</p>
                        <li>Chefferie de projet</li>
                        <p className="para">{competencies?.Chefferie_de_projet_Autres}</p>
                    </ul>
                );

            case "Système Linux":
                return (
                    <ul className="d-flex">
                        <div>
                            <li>LINUX</li>
                            <p className="para">{competencies?.LINUX_SL}</p>
                        </div>
                        <div>
                            <li>Redhat</li>
                            <p className="para">{competencies?.Redhat_SL}</p>
                        </div>
                        <div>
                            <li>Ubuntu</li>
                            <p className="para">{competencies?.Ubuntu_SL}</p>
                        </div>
                        <div>
                            <li>Shell</li>
                            <p className="para">{competencies?.Shell_SL}</p>
                        </div>
                        <div>
                            <li>Apache</li>
                            <p className="para">{competencies?.Apache_SL}</p>
                        </div>
                        <div>
                            <li>Docker</li>
                            <p className="para">{competencies?.Docker_SL}</p>
                        </div>
                        <div>
                            <li>Kubernetes</li>
                            <p className="para">{competencies?.Kubernetes_SL}</p>
                        </div>
                        <div>
                            <li>Ansible</li>
                            <p className="para">{competencies?.Ansible_SL}</p>
                        </div>
                        <div>
                            <li>Terraform</li>
                            <p className="para">{competencies?.Terraform_SL}</p>
                        </div>
                        <div>
                            <li>Puppet</li>
                            <p className="para">{competencies?.Puppet_SL}</p>
                        </div>
                        <div>
                            <li>Jenkins</li>
                            <p className="para">{competencies?.Jenkins_SL}</p>
                        </div>
                        <div>
                            <li>GIT</li>
                            <p className="para">{competencies?.GIT_SL}</p>
                        </div>
                        <div>
                            <li>Openstack</li>
                            <p className="para">{competencies?.Openstack_SL}</p>
                        </div>
                        <div>
                            <li>Azure</li>
                            <p className="para">{competencies?.Azure_SL}</p>
                        </div>
                        <div>
                            <li>AWS</li>
                            <p className="para">{competencies?.AWS_SL}</p>
                        </div>
                        <div>
                            <li>Devops</li>
                            <p className="para">{competencies?.Devops_SL}</p>
                        </div>
                    </ul>
                );

            case "Système Windows":
                return (
                    <ul>
                        <div className="row p-1 mb-3">
                            <div className="col">
                                <h4>Windows Server</h4>
                                <p className="para">{competencies?.Windows_Server_SW}</p>
                            </div>
                            <div className="col">
                                <h4>Active-Directory</h4>
                                <p className="para">{competencies?.Active_Directory_SW}</p>
                            </div>
                            <div className="col">
                                <h4>Office-365</h4>
                                <p className="para">{competencies?.Office_365_SW}</p>
                            </div>
                        </div>

                        <div className="row p-1 mb-3">
                            <div className="col">
                                <h4>EXCHANGE</h4>
                                <p className="para">{competencies?.EXCHANGE_SW}</p>
                            </div>
                            <div className="col">
                                <h4>SHAREPOINT</h4>
                                <p className="para">{competencies?.SHAREPOINT_SW}</p>
                            </div>
                            <div className="col">
                                <h4>SCCM</h4>
                                <p className="para">{competencies?.SCCM_SW}</p>
                            </div>
                        </div>

                        <div className="row p-1 mb-3">
                            <div className="col">
                                <h4>SCOM</h4>
                                <p className="para">{competencies?.SCOM_SW}</p>
                            </div>
                            <div className="col">
                                <h4>IIS</h4>
                                <p className="para">{competencies?.IIS_SW}</p>
                            </div>
                            <div className="col">
                                <h4>Powershell</h4>
                                <p className="para">{competencies?.Powershell_SW}</p>
                            </div>
                        </div>

                        <div className="row p-1 mb-3">
                            <div className="col">
                                <h4>Hyper-V</h4>
                                <p className="para">{competencies?.HyperV_SW}</p>
                            </div>
                            <div className="col">
                                <h4>Azure</h4>
                                <p className="para">{competencies?.Azure_SW}</p>
                            </div>
                            <div className="col">
                                <h4>AWS</h4>
                                <p className="para">{competencies?.AWS_SW}</p>
                            </div>
                        </div>

                        <div className="row p-1 mb-3">
                            <div className="col">
                                <h4>Devops</h4>
                                <p className="para">{competencies?.Devops_SW}</p>
                            </div>
                            <div className="col">
                                <h4>Docker</h4>
                                <p className="para">{competencies?.Docker_SW}</p>
                            </div>
                            <div className="col">
                                <h4>Kubernetes</h4>
                                <p className="para">{competencies?.Kubernetes_SW}</p>
                            </div>
                        </div>
                    </ul>
                );

            case "Base de données":
                return (
                    <ul>
                        <li>SQL-Server</li>
                        <p className="para">{competencies?.SQL_Server_BDD}</p>
                        <li>MySQL</li>
                        <p className="para">{competencies?.MySQL_BDD}</p>
                        <li>ORACLE</li>
                        <p className="para">{competencies?.ORACLE_BDD}</p>
                        <li>POSTGRES</li>
                        <p className="para">{competencies?.POSTGRES_BDD}</p>
                        <li>Oracle-RAC</li>
                        <p className="para">{competencies?.Oracle_RAC_BDD}</p>
                        <li>INGRES</li>
                        <p className="para">{competencies?.INGRES_BDD}</p>
                    </ul>
                );

            case "Développeur Java" ||
                "Développeur dotnet" ||
                "Développeur Python" ||
                "Développeur PHP" ||
                "Développeur mais autres technologies":
                return (
                    <ul>
                        <li>Python</li>
                        <p className="para">{competencies?.Python_Dev}</p>
                        <li>PHP</li>
                        <p className="para">{competencies?.PHP_Dev}</p>
                        <li>Java</li>
                        <p className="para">{competencies?.Java_Dev}</p>
                        <li>Spring</li>
                        <p className="para">{competencies?.Spring_Dev}</p>
                        <li>Angular</li>
                        <p className="para">{competencies?.Angular_Dev}</p>
                        <li>Angular_JS</li>
                        <p className="para">{competencies?.Angular_JS_Dev}</p>
                        <li>Laravel</li>
                        <p className="para">{competencies?.Laravel_Dev}</p>
                        <li>JavaScript</li>
                        <p className="para">{competencies?.JavaScript_Dev}</p>
                        <li>React_JS</li>
                        <p className="para">{competencies?.React_JS_Dev}</p>
                        <li>Vue_JS</li>
                        <p className="para">{competencies?.Vue_JS_Dev}</p>
                        <li>Node_JS</li>
                        <p className="para">{competencies?.Node_JS_Dev}</p>
                        <li>C_sharp</li>
                        <p className="para">{competencies?.C_sharp_Dev}</p>
                        <li>DotNET</li>
                        <p className="para">{competencies?.DotNET_Dev}</p>
                        <li>Langage_R</li>
                        <p className="para">{competencies?.Langage_R_Dev}</p>
                        <li>Ruby</li>
                        <p className="para">{competencies?.Ruby_Dev}</p>
                        <li>Symfony</li>
                        <p className="para">{competencies?.Symfony_Dev}</p>
                    </ul>
                );

            case "Virtualisation":
                return (
                    <ul>
                        <li>Avancer dans ce type de Profile</li>
                        <p className="para">{competencies?.profile_change_Virt}</p>
                        <li>Windows-Server</li>
                        <p className="para">{competencies?.Windows_Server_Virt}</p>
                        <li>Office-365</li>
                        <p className="para">{competencies?.Office_365_Virt}</p>
                        <li>LINUX</li>
                        <p className="para">{competencies?.LINUX_Virt}</p>
                        <li>Redhat</li>
                        <p className="para">{competencies?.Redhat_Virt}</p>
                        <li>Ubuntu</li>
                        <p className="para">{competencies?.Ubuntu_Virt}</p>
                        <li>Shell</li>
                        <p className="para">{competencies?.Shell_Virt}</p>
                        <li>Apache</li>
                        <p className="para">{competencies?.Apache_Virt}</p>
                        <li>Docker</li>
                        <p className="para">{competencies?.Docker_Virt}</p>
                        <li>Ansible</li>
                        <p className="para">{competencies?.Ansible_Virt}</p>
                        <li>Terraform</li>
                        <p className="para">{competencies?.Terraform_Virt}</p>
                        <li>Cisco</li>
                        <p className="para">{competencies?.Cisco_Virt}</p>
                        <li>Nexus</li>
                        <p className="para">{competencies?.Nexus_Virt}</p>
                        <li>Fortinet</li>
                        <p className="para">{competencies?.Fortinet_Virt}</p>
                        <li>Palo-Alto</li>
                        <p className="para">{competencies?.PaloAlto_Virt}</p>
                        <li>F5</li>
                        <p className="para">{competencies?.F5_Virt}</p>
                        <li>ACE</li>
                        <p className="para">{competencies?.ACE_Virt}</p>
                        <li>Cisco-ASA</li>
                        <p className="para">{competencies?.CiscoASA_Virt}</p>
                    </ul>
                );

            case "Réseaux et sécurité":
                return (
                    <ul>
                        <li>
                            Certification CCNP (Cisco) ou HCIP Switching et Routing (Huawei)
                        </li>
                        <p className="para">{competencies?.ccnp_NetSec}</p>
                        <li>Validité de la certification CCNA (Cisco)</li>
                        <p className="para">{competencies?.ccnaNetSec}</p>
                        <li>Cisco</li>
                        <p className="para">{competencies?.ciscoNetSec}</p>
                        <li>Nexus</li>
                        <p className="para">{competencies?.nexusNetSec}</p>
                        <li>MPLS</li>
                        <p className="para">{competencies?.MPLSNetSec}</p>
                        <li>Palo-Alto</li>
                        <p className="para">{competencies?.PaloAltoNetSec}</p>
                        <li>F5</li>
                        <p className="para">{competencies?.F5NetSec}</p>
                        <li>ACE</li>
                        <p className="para">{competencies?.ACENetSec}</p>
                        <li>Cisco-ASA</li>
                        <p className="para">{competencies?.CiscoASANetSec}</p>
                        <li>Fortinet</li>
                        <p className="para">{competencies?.FortinetNetSec}</p>
                    </ul>
                );

            case "Réseaux et système":
                return (
                    <ul>
                        <li>Avancer dans ce type de Profile</li>
                        <p className="para">{competencies?.profile_Change_SR}</p>
                        <li>Windows-Server</li>
                        <p className="para">{competencies?.Windows_Server_SR}</p>
                        <li>Active-Directory</li>
                        <p className="para">{competencies?.Directory_SR}</p>
                        <li>Office-365</li>
                        <p className="para">{competencies?.Office_365_SR}</p>
                        <li>EXCHANGE</li>
                        <p className="para">{competencies?.EXCHANGE_SR}</p>
                        <li>SHAREPOINT</li>
                        <p className="para">{competencies?.SHAREPOINT_SR}</p>
                        <li>SCCM</li>
                        <p className="para">{competencies?.SCCM_SR}</p>
                        <li>SCOM</li>
                        <p className="para">{competencies?.SCOM_SR}</p>
                        <li>IIS</li>
                        <p className="para">{competencies?.IIS_SR}</p>
                        <li>Powershell</li>
                        <p className="para">{competencies?.Powershell_SR}</p>
                        <li>Jenkins</li>
                        <p className="para">{competencies?.Jenkins_SR}</p>
                        <li>GIT</li>
                        <p className="para">{competencies?.GIT_SR}</p>
                        <li>Openstack</li>
                        <p className="para">{competencies?.Openstack_SR}</p>
                        <li>
                            Certification CCNP (Cisco) ou HCIP Switching et Routing (Huawei)
                        </li>
                        <p className="para">{competencies?.CCNP_SR}</p>
                        <li>Validité de la certification CCNA (Cisco)</li>
                        <p className="para">{competencies?.CCNA_SR}</p>
                        <li>Cisco</li>
                        <p className="para">{competencies?.Cisco_SR}</p>
                        <li>Nexus</li>
                        <p className="para">{competencies?.Nexus_SR}</p>
                        <li>Fortinet</li>
                        <p className="para">{competencies?.Fortinet_SR}</p>
                        <li>Palo-Alto</li>
                        <p className="para">{competencies?.PaloAlto_SR}</p>
                        <li>F5</li>
                        <p className="para">{competencies?.F5_SR}</p>
                        <li>ACE</li>
                        <p className="para">{competencies?.ACE_SR}</p>
                        <li>Cisco-ASA</li>
                        <p className="para">{competencies?.CiscoASA_SR}</p>
                        <li>Hyper-V</li>
                        <p className="para">{competencies?.HyperV_SR}</p>
                        <li>Azure</li>
                        <p className="para">{competencies?.Azure_SR}</p>
                        <li>AWS</li>
                        <p className="para">{competencies?.AWS_SR}</p>
                        <li>Devops</li>
                        <p className="para">{competencies?.Devops_SR}</p>
                        <li>LINUX</li>
                        <p className="para">{competencies?.LINUX_SR}</p>
                        <li>Redhat</li>
                        <p className="para">{competencies?.Redhat_SR}</p>
                        <li>Ubuntu</li>
                        <p className="para">{competencies?.Ubuntu_SR}</p>
                        <li>Shell</li>
                        <p className="para">{competencies?.Shell_SR}</p>
                        <li>Apache</li>
                        <p className="para">{competencies?.Apache_SR}</p>
                        <li>Docker</li>
                        <p className="para">{competencies?.Docker_SR}</p>
                        <li>Ansible</li>
                        <p className="para">{competencies?.Ansible_SR}</p>
                        <li>Terraform</li>
                        <p className="para">{competencies?.Terraform_SR}</p>
                        <li>Puppet</li>
                        <p className="para">{competencies?.Puppet_SR}</p>
                    </ul>
                );
            case "BI" || "Big Data":
                return (
                    <ul>
                        <li>Hadoop</li>
                        <p className="para">{competencies?.Hadoop_BigDataBI}</p>
                        <li>Cloudera_HDFS</li>
                        <p className="para">{competencies?.Cloudera_BigDataBI}</p>
                        <li>Apache_Hive</li>
                        <p className="para">{competencies?.Apache_Hive_BigDataBI}</p>
                        <li>Apache_NiFi</li>
                        <p className="para">{competencies?.Apache_NiFi_BigDataBI}</p>
                        <li>Spark</li>
                        <p className="para">{competencies?.Spark_BigDataBI}</p>
                        <li>Talend</li>
                        <p className="para">{competencies?.Talend_BigDataBI}</p>
                        <li>Power_BI</li>
                        <p className="para">{competencies?.Power_BI_BigDataBI}</p>
                    </ul>
                );

            default:
        }
    }; */

    const [EditedProfile, setEditedProfile] = useState({})
    const [familySituation, setFamilySituation] = useState(profileC?.familySituation)
    let user = JSON.parse(localStorage.getItem("user"));

    const HandleChangeInput = (e) => {
        setEditedProfile({ ...EditedProfile, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        setFamilySituation(profileC?.familySituation)

    }, [profileC])

    const Editing = async() => {

        EditedProfile.email && await EditUserParameters({ userID: user?._id, email: EditedProfile?.email }, candidateUser?._id)

        if (EditedProfile.email) { user.email = EditedProfile.email; }
        if (EditedProfile.email) { localStorage.setItem("user", JSON.stringify(user)); }

    
        await EditProfileFrCandidate({ ...EditedProfile, familySituation }, user?._id)
    }


    return (
        <div className="container">
            {/*profileC && <ProfilePartie1 getUser={getUser} getProfileCandidate={getProfileCandidate} profileC={profileC} candidateUser={candidateUser} t={t} />*/}
            <br />
            {profileC ?
                <Container>
                    <Card className="shadow">
                        <CardHeader>
                            <Row className="p-2 bd-highlight">
                                <Col sm='6'>
                                    <h6 className="heading-small text-muted mb-4">Informations</h6>
                                </Col>

                                <Col sm='6'>
                                    {user?._id == candidateUser?._id && <Button onClick={Editing} className='d-flex' style={{ backgroundColor: '#5e72e4', color: 'white', float: 'right', whiteSpace: 'normal' }}>
                                        <svg style={{ height: '1.2rem' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                            <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                                            <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                                        </svg>
                                        <span>
                                            {t('save')}
                                        </span>
                                    </Button>}
                                </Col>

                            </Row>
                        </CardHeader>
                        <CardBody className="bg-white">
                            <Form>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col lg="5">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-email"
                                                >
                                                    Email
                                                </label>
                                                <Input
                                                    name='email'
                                                    className="form-control-alternative"
                                                    id="input-email"
                                                    defaultValue={candidateUser?.email}
                                                    type="email"
                                                    onChange={HandleChangeInput}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="7">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-gender"
                                                >
                                                    WhatsApp
                                                </label>
                                                <Input
                                                    name='whatsappnumber'
                                                    className="form-control-alternative"
                                                    defaultValue={profileC?.whatsappnumber}
                                                    id="input-whatsappnumber"
                                                    onChange={HandleChangeInput}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col lg="5">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-age">
                                                    Age
                                                </label>
                                                <Input
                                                    name='age'
                                                    className="form-control-alternative"
                                                    defaultValue={profileC?.age}
                                                    id="input-age"
                                                    onChange={HandleChangeInput}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="7">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-linkedin"
                                                >
                                                    Linkedin
                                                </label>
                                                <Input
                                                    name='linkedin'
                                                    className="form-control-alternative"
                                                    defaultValue={profileC?.linkedin}
                                                    id="input-username"
                                                    onChange={HandleChangeInput}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        {/* <Col lg="3">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-village"
                                                >
                                                    Village
                                                </label>
                                                <Input
                                                    name='village'
                                                    className="form-control-alternative"
                                                    defaultValue={profileC?.village}
                                                    id="input-username"
                                                    onChange={HandleChangeInput}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-country"
                                                >
                                                    Pays
                                                </label>
                                                <Select
                                                    name="country"
                                                    className="w-full"
                                                    options={options_Pays}
                                                    defaultValue={options_Pays.find((option) => option.value === profileC?.country)}
                                                    //value={options_Pays.find((option) => option.value === profileC?.country)}
                                                    onChange={(option) => { setCountry(option.value) }}
                                                />
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-gender"
                                                >
                                                    Gender
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    defaultValue={profileC?.gender}
                                                    id="input-age"
                                                    type="text"
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <label className="px-lg-3">{t('FamilyStatus')}</label>
                                            <Select
                                                className="w-full"
                                                options={options_family_Situation}
                                                defaultValue={options_family_Situation.find((option) => option.value === profileC?.familySituation)}
                                                //value={options_family_Situation.find((option) => option.value === profileC?.familySituation)}
                                                onChange={(option) => setFamilySituation(option.value)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <hr className="my-4" />
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
                :
                <Row>
                    <Col sm='8'>
                        {user?._id === candidateUser?._id && <Alert color='secondary'>{t('ProfileMessage')}</Alert>}
                    </Col>

                    <Col sm='4'>
                        {user?._id === candidateUser?._id && <FillProfileModal t={t} />}
                    </Col>
                </Row>

            }

            {/* sharedprofile part even profile candidate doc is not created */
            (user?._id !== candidateUser?._id) && !profileC && 
            <>
                <ProfilePartie1 getUser={getUser} getProfileCandidate={getProfileCandidate} profileC={profileC} candidateUser={candidateUser} t={t} />
                <br/>
                <Container>
                    <Card className="shadow">
                        <CardHeader>
                            <Row className="p-2 bd-highlight">
                                <Col sm='6'>
                                    <h6 className="heading-small text-muted mb-4">Informations</h6>
                                </Col>

                                <Col sm='6'>
                                    
                                </Col>

                            </Row>
                        </CardHeader>
                        <CardBody className="bg-white">
                            <Form>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col lg="5">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-email"
                                                >
                                                    Email
                                                </label>
                                                <Input
                                                    name='email'
                                                    className="form-control-alternative"
                                                    id="input-email"
                                                    value={candidateUser?.email}
                                                    type="email"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="7">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-gender"
                                                >
                                                    WhatsApp
                                                </label>
                                                <Input
                                                    name='whatsappnumber'
                                                    className="form-control-alternative"
                                                    value={profileC?.whatsappnumber}
                                                    id="input-whatsappnumber"
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col lg="5">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-age">
                                                    Age
                                                </label>
                                                <Input
                                                    name='age'
                                                    className="form-control-alternative"
                                                    value={profileC?.age}
                                                    id="input-age"
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="7">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-linkedin"
                                                >
                                                    Linkedin
                                                </label>
                                                <Input
                                                    name='linkedin'
                                                    className="form-control-alternative"
                                                    value={profileC?.linkedin}
                                                    id="input-username"
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-gender"
                                                >
                                                    Gender
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    value={profileC?.gender}
                                                    id="input-age"
                                                    type="text"
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <label className="px-lg-3">Situation familiale</label>
                                            <Input
                                                type='text'
                                                //options={options_family_Situation}
                                                value={profileC?.familySituation}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <hr className="my-4" />
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
            

            }

            <br />

            <CandidateCompetencies competencies={competencies} user={user} candidateUser={candidateUser} getCandidateCompetencies={getCandidateCompetencies} t={t} />
                       <br />
            <CardComp header={`${t('videoTest')} (${jobApplication?.length ? jobApplication?.length : 0})`}>

                {!candidateProgressProfile?.interviewPassed ?
                    <Row>
                        {(user?._id === candidateUser?._id) && <a href={candidateUser?.location === 'France' ? '/infrancecandidate/contract/generalinterviews' : '/outfrapplypermanentfr/contract/generalinterviews'} target="_blank" rel="noopener noreferrer">
                                <Button className='ml-2'>{t('interviewPass')}</Button>
                        </a>}
                    </Row>
                    :
                    <Row>
                        <Col sm='7'>
                            {jobApplication?.map((jobApp, i) => <MyInterviewTitles key={i} jobID={jobApp.jobID} />)}
                        </Col>
                        <Col sm='5'>
                            {(user?._id === candidateUser?._id) && <a href={candidateUser?.location === 'France' ? '/infrancecandidate/contract/generalinterviews' : '/outfrapplypermanentfr/contract/generalinterviews'} target="_blank" rel="noopener noreferrer">
                                <Button className='ml-2'>{t('interviewPassother')}</Button>
                            </a>}
                        </Col>

                    </Row>
                }

            </CardComp>
        </div>
    );
};

export default FormComp;
