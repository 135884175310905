import axios from "axios";
import Questions from "components/recruteur/Questions";
import UpdateForm from "components/UpdateForm";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import {
  Modal,
  ModalBody,
  ModalFooter,
  // Button,
  Form,
  FormGroup,
  // Container,
  Label,
  Input,
  Row,
  Col,
  // Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";

import { Box, Container, Button, Card, Grid, MenuItem, TextField } from "@mui/material";

import Offres from "components/recruteur/jobs";
import config from "config";
import Header from "components/Headers/Header";
import EmployerLayout from "layouts/EmployerLayout";
import { useTranslation } from "react-i18next";
import Popups from "components/other/Popup";
const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function HomeEmployer({ jobId }) {
  const history = useHistory();
  const [job, setJob] = useState({});
  const [created, setCreated] = useState("");
  const [endDate, setEndDate] = useState("");
  const [interviewUrl, setInterviewUrl] = useState("");
  const [affiche, setAffiche] = useState({
    update: false,
    questions: false,
  });

  const [openTab, setOpenTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPostul, setModalPostul] = useState(false);
  const [CVinput, setCVinput] = useState(false);
  const [CV, setCV] = useState("");
  const [candidatID, setCandidatID] = useState("");
  const [pathJob, setPathJob] = useState("");
  const [pathComp, setPathComp] = useState("");

  const [data1, setData1] = useState([]);
  const [data, setData] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const { t, i18n } = useTranslation();


  const fetchData = async () => {
    instance.get("jobs").then((resp) => {
      setJobs(resp.data);
      const data = resp.data;
      const DATA = [];
      // console.log("data", data);
      if (data.length >= 3) {
        for (let i = data.length - 1; i >= data.length - 3; i--) {
          DATA.push(data[i]);
        }
      } else {
        for (let i = data.length - 1; i >= 0; i--) {
          DATA.push(data[i]);
        }
      }
      setData(DATA);
    });
  };
  const fetchData1 = async () => {
    instance.get("competitions").then((resp) => {
      setCompetitions(resp.data);
      const data = resp.data;
      const DATA = [];
      // console.log("data", data);
      if (data.length >= 3) {
        for (let i = data.length - 1; i >= data.length - 3; i--) {
          DATA.push(data[i]);
        }
      } else {
        for (let i = data.length - 1; i >= 0; i--) {
          DATA.push(data[i]);
        }
      }
      setData1(DATA);
    });
  };
  useEffect(() => {
    fetchData();
    fetchData1();
    handlePath();
    
  }, [pathJob, pathComp]);

  const deleteJob = () => {
    instance.delete(`jobs/${jobId}`).then(() => {
      history.push("/admin/tab2");
    });
  };
  const handlePath = () => {
    if (JSON.parse(localStorage.getItem("user"))) {
      switch (JSON.parse(localStorage.getItem("user")).role) {
        case "employer":
          setPathJob("/admin/tab2");     
          setPathComp("/admin/tab2comp");  
          break;    
        case "candidat":
          setPathJob("/candidat/tab2");   
          setPathComp("/candidat/tab2comp");         
      }
    } else {
      setPathJob("/home/jobs");    
      setPathComp("/home/competitions");    
    }
  };

  return (
    <>
    <EmployerLayout />


      <Container>

      <Row className="homePageStyle">
        <Col lg="2">
        </Col>
        <Col lg="10">
        <h2>{t('Recruiter_HOME_key1')}</h2>
          <p>
          {t('Recruiter_HOME_key2')}.
          </p>
          <p>
          {t('Recruiter_HOME_key3')}.
          </p>
          {' '}
          <span> </span>
          <h3>{t('Recruiter_HOME_key4')} ? </h3>
        <h2> 
          <Button
            color="primary"
            variant="contained"
            style={{
              // backgroundColor: '#2196f3',
              justifyContent: "center",
              size: "lg",
              alignItems: "center",
              

            }}
            onClick={()=>history.push('/employer/jobs')}
            //href={`/employer/jobs/`}
          >
          {t('Recruiter_HOME_key5')}
          </Button>
          </h2>
        </Col>

      </Row>
      <Row>
        <Col lg="2">
        </Col>
        <Col lg="6">

            <h2>{t('Recruiter_HOME_key6')}</h2>
            <br></br>
            <br></br>
            <p>{t('Recruiter_HOME_key7')}</p>
            <p>{t('Recruiter_HOME_key8')}</p>
            <p>{t('Recruiter_HOME_key9')}</p>
            <p>{t('Recruiter_HOME_key10')}</p>

            <h2> 
        <Button
            variant="contained" 
            color="primary" 
            //type="submit" 
            
            style={{
              backgroundColor: '#2196f3',
              justifyContent: "center",
              size: "lg",
              alignItems: "center"

            }}
            onClick={()=> history.push('/employer/jobs')}
          //href={`/employer/talentsprofiles`}
          //href={`/employer/jobs`}
          >
            {t('Recruiter_HOME_key11')}
          </Button>
          </h2>
            
        </Col>
         <Col lg="4">
          <img
            src={`assets/img/brand/cv_video_1.jpg`}
            //className="rounded-circle"
            onError={(e) => {
              e.target.src =
                require("assets/img/brand/cv_video_1.jpg").default;
            }}
            style={{
              width: "100%",
              height: "75%",
              paddingRight: "10%",
              paddingLeft: 0,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "80px",
              

              //objectFit: "cover",
            }}
            //alt="Responsive image"
          />
         </Col>
      </Row>
      <Row>
        <Col lg="2">
        </Col>
        <Col lg="5">
          <img
            src={`assets/img/brand/cv_video_2.jpg`}
            //className="rounded-circle"
            onError={(e) => {
              e.target.src =
                require("assets/img/brand/cv_video_2.jpg").default;
            }}
            style={{
              width: "100%",
              height: "75%",
              paddingRight: 0,
              paddingLeft: "10%",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "80px",
              //paddingTop: "60px",
              

              //objectFit: "cover",
            }}
            //alt="Responsive image"
          />
        </Col>
        <Col lg="5">

            <h2>{t('Recruiter_HOME_key12')}</h2>
            <br></br>
            <br></br>
            <p>{t('Recruiter_HOME_key13')}</p>
            <p>{t('Recruiter_HOME_key14')}</p>
            <p>{t('Recruiter_HOME_key15')}</p>
            <h2> 
        <Button
            variant="contained" 
            color="primary" 
            //type="submit" 
            
            style={{
              backgroundColor: '#2196f3',
              justifyContent: "center",
              size: "lg",
              alignItems: "center"

            }}
            onClick={()=> history.push('/employer/jobs')}
          // href={`/employer/talentsprofiles/`}
          //href={`/employer/jobs`}
          >
          {t('Recruiter_HOME_key11')}
          </Button>
          </h2>
            

        </Col>
      </Row>
      <Popups />

      </Container>    

    </>
  );
}

export default HomeEmployer;
